import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import config from '../config';

// Utility to get the CSRF token from cookies
function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== "") {
        const cookies = document.cookie.split(";");
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.substring(0, name.length + 1) === name + "=") {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}

function ActivateAccount() {
    const { userId, token } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const activateAccount = async () => {
            try {
                const csrfToken = getCookie("csrftoken");
                console.log("csrfToken", csrfToken); // Debug: Check if token is retrieved correctly

                const response = await axios.get(`${config.apiUrl}/activate/${userId}/${token}/`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRFToken': csrfToken, // Include CSRF token
                    },
                    withCredentials: true, 
                });

                if (response.status === 200) {
                    toast.success("Account activated successfully! Redirecting to login...");
                    setTimeout(() => {
                        navigate('/login'); 
                    }, 2000); // Delay for user feedback
                }
            } catch (error) {
                if (error.response) {
                    // Server responded with an error
                    const errorData = error.response.data;
                    toast.error(`Activation failed: ${errorData.detail || 'Unknown error'}`);
                } else {
                    // No response from the server or other error
                    toast.error("An error occurred during activation. Please try again.");
                    console.error("Error activating account:", error.message);
                }
            }
        };

        activateAccount();
    }, [userId, token, navigate]);

    return <div>Activating your account...</div>;
}

export default ActivateAccount;
