import React, { useContext } from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { UserProvider, UserContext } from './login/UserContext';
import ActivateAccount from './login/ActivateAccount.js';
import WorkflowDetail from "./pages/UserDashboard/WorkflowDetail";
import Login from "./login/Login";
import Register from "./login/Register";
import AddWorkflow from "./pages/AdminDashboard/AddWorkflow";
import BrowseWorkflowsList from "./pages/BrowseWorkflows/BrowseWorkflowList";
import './App.css';
import PreviewWorkflowDetails from './pages/BrowseWorkflows/PreviewWorkflowDetails';
import EditProfile from './login/EditProfile';
import WorkflowStepDetail from './pages/UserDashboard/WorkflowStepDetail';
import AdminWorkflowList from './pages/AdminDashboard/AdminWorkflowList';
import EditWorkflow from "./pages/AdminDashboard/EditWorkflow";
import ViewWorkflow from "./pages/AdminDashboard/ViewWorkflow";
import ViewUserTasks from './pages/AdminDashboard/ViewUserTasks';
import UserWorkflowList from './pages/UserDashboard/UserWorkflowList';
import Logout from './login/Logout';
import PasswordResetConfirm from './login/password_reset_confirm';
import AuthPage from './login/AuthPage';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Sidebar from './components/common/Sidebar';

function App() {
  return (
    <UserProvider>
      <Router>
        <AppContent />
      </Router>
    </UserProvider>
  );
}

function AppContent() {
  const location = useLocation();

  // Define routes where the Sidebar should not be displayed
  const noSidebarRoutes = ['/login', '/register', '/api/reset', '/api/activate'];

  // Check if current location matches any of the no-sidebar routes
  const shouldShowSidebar = !noSidebarRoutes.some((path) =>
    location.pathname.startsWith(path)
  );

  return (
    <div className="App">
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      {/* Conditionally render the Sidebar */}
      {shouldShowSidebar && <Sidebar />}

      <Routes>
        <Route path="/" element={<Navigate to="/user-dashboard" />} />

        <Route path="/login" element={<AuthPage />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/api/activate/:userId/:token/" element={<ActivateAccount />} />
        <Route path="/api/reset/:uidb64/:token/" element={<PasswordResetConfirm />} />

        <Route path="/register" element={<AuthPage />} />
        <Route path="/profile" element={<ProtectedRoute><EditProfile /></ProtectedRoute>} />

        <Route path="/user-dashboard" element={<ProtectedRoute><UserWorkflowList /></ProtectedRoute>} />
        <Route path="/user-dashboard/workflows/:id" element={<ProtectedRoute><WorkflowDetail /></ProtectedRoute>} />
        <Route path="/user-dashboard/workflows/:id/step/:stepId" element={<ProtectedRoute><WorkflowStepDetail /></ProtectedRoute>} />

        <Route path="/admin-dashboard" element={<ProtectedRoute><AdminWorkflowList /></ProtectedRoute>} />
        <Route path="/admin-dashboard/create-workflow" element={<ProtectedRoute><AddWorkflow /></ProtectedRoute>} />
        <Route path="/admin-dashboard/edit-workflow/workflows/:id" element={<ProtectedRoute><EditWorkflow /></ProtectedRoute>} />
        <Route path="/admin-dashboard/view/workflows/:id" element={<ProtectedRoute><ViewWorkflow /></ProtectedRoute>} />
        <Route path="/admin-dashboard/view/workflows/:workflowId/users/:userId" element={<ProtectedRoute><ViewUserTasks /></ProtectedRoute>} />

        <Route path="/browse-workflows" element={<ProtectedRoute><BrowseWorkflowsList /></ProtectedRoute>} />
        <Route path="/browse-workflows/workflows/:id" element={<ProtectedRoute><PreviewWorkflowDetails /></ProtectedRoute>} />

        <Route path="*" element={<Navigate to="/user-dashboard" />} />
      </Routes>
    </div>
  );
}

function ProtectedRoute({ children }) {
    const { user, loading } = useContext(UserContext);

    if (loading) {
        return <div>Loading...</div>; // Or any other loading indicator
    }

    if (!user) {
        return <Navigate to="/login" replace />;
    }

    return children;
}

export default App;
