// src/pages/AdminWorkflowList.js

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import AdminWorkflowCard from '../../components/workflow/AdminWorkflowCard';
import config from '../../config';
import { FaPlus } from 'react-icons/fa';
import styles from '../../styles/pages/AdminDashboard/AdminWorkflowList.module.css';
import DashboardTitle from '../../components/common/DashboardTitle'; // Import DashboardTitle
import NavTabs from '../../components/common/NavTabs'; // Import NavTabs

function AdminWorkflowList() {
  const [workflows, setWorkflows] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(true);
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState('Manage'); // Default selected tab

  const navigate = useNavigate();

  const toggleProfileDropdown = () => {
    setIsProfileOpen(!isProfileOpen);
  };

  useEffect(() => {
    const fetchWorkflows = async () => {
      try {
        const response = await axios.get(`${config.apiUrl}/workflows/`, {
          headers: { Authorization: `Token ${localStorage.getItem('authToken')}` },
        });
        setWorkflows(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching workflows:', error);
        setLoading(false);
      }
    };
    fetchWorkflows();
  }, []);

  const handleDelete = (workflowId) => {
    if (window.confirm('Are you sure you want to delete this workflow?')) {
      axios
        .delete(`${config.apiUrl}/workflows/${workflowId}`, {
          headers: {
            Authorization: `Token ${localStorage.getItem('authToken')}`,
          },
        })
        .then(() => {
          setWorkflows(workflows.filter((workflow) => workflow.id !== workflowId));
        })
        .catch((error) => {
          console.error('Failed to delete the workflow:', error);
        });
    }
  };

  // Filter workflows based on search query
  const filteredWorkflows = workflows.filter((workflow) =>
    workflow.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Handle navigation to create workflow page
  const handleCreateWorkflow = () => {
    navigate('/admin-dashboard/create-workflow');
  };

  // Define tabs
  const tabs = [
    { label: 'Manage', link: '/admin-dashboard/workflows' },
  ];

  return (
    <div className="dashboard-container">
      <div className="content">
        {/* Dashboard Header */}
        <div className="dashboard-header">
          <DashboardTitle
            title="Manage Workflows"
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            searchPlaceholder="Search workflows..."
          />
          <NavTabs tabs={tabs} selectedTab={selectedTab} onTabSelect={setSelectedTab} />
        </div>

        {/* New Workflow Actions Container */}
        <div className={styles['workflow-actions']}>
          <button
            onClick={handleCreateWorkflow}
            className={styles['create-workflow-button']}
          >
            <FaPlus className="button-icon" />
            Create New Workflow
          </button>
        </div>

        {/* Workflow List */}
        <div className="workflow-list">
          {loading ? (
            <div className="loading-container">
              <div className="loading-spinner"></div>
              <div className="loading-message">Loading workflows...</div>
            </div>
          ) : filteredWorkflows.length > 0 ? (
            filteredWorkflows.map((workflow) => (
              <AdminWorkflowCard
                key={workflow.id}
                workflow={workflow}
                onDelete={() => handleDelete(workflow.id)}
              />
            ))
          ) : (
            <div>No workflows found.</div>
          )}
        </div>
      </div>
    </div>
  );
}

export default AdminWorkflowList;
