import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import parse from 'html-react-parser';
import config from '../../config';
import styles from '../../styles/pages/UserDashboard/WorkflowDetail.module.css';
import WorkflowTasks from '../../components/WorkflowDetails/WorkflowTasks';

function WorkflowDetail() {
    const navigate = useNavigate();
    const [workflow, setWorkflow] = useState(null);
    const { id } = useParams();
    const [darkMode, setDarkMode] = useState(false);
    const [notification, setNotification] = useState(null);
    const [helpOpen, setHelpOpen] = useState(false);

    useEffect(() => {
        const fetchWorkflow = async () => {
            try {
                const authToken = localStorage.getItem('authToken');
                const response = await axios.get(`${config.apiUrl}/user-workflows/${id}/`, {
                    headers: {
                        'Authorization': `Token ${authToken}`
                    }
                });
                console.log(`tasks in workflow:${response.data.workflow.tasks}`)
                setWorkflow(response.data);
            } catch (error) {
                console.error('Failed to fetch workflow details:', error);
                navigate('/user-dashboard');
            }
        };

        fetchWorkflow();
    }, [id, navigate]);

    if (!workflow) {
        return (
            <div className={styles.loadingContainer}>
                <div className={styles.loader}></div>
                <div className={styles.loadingText}>Loading workflow details...</div>
            </div>
        );
    }

    if (!workflow.workflow || !workflow.workflow.tasks) {
        return <h2 className={styles.loadingText}>No tasks found.</h2>;
    }

    const tasks = workflow.workflow.tasks;
    const completedCount = tasks.filter(task => task.status === "Done").length;
    const totalTasks = tasks.length;
    const completionPercent = totalTasks ? (completedCount / totalTasks) * 100 : 0;

    const toggleHelp = () => {
        setHelpOpen(!helpOpen);
    };

    return (
        <div className={darkMode ? styles.darkModeWrapper : ''}>
          


            {/* Notification outside of pageWrapper 
            {notification && (
                <div className={styles.notification} role="alert" aria-live="assertive">
                    {notification}
                </div>
            )}

            {/* Toolbar outside of pageWrapper 
            <div className={styles.toolbar}>
                <button onClick={() => navigate('/user-dashboard')} className={styles.backButton} aria-label="Back to Workflows">← Back</button>
                <button onClick={() => setDarkMode(!darkMode)} className={styles.toggleModeButton} aria-label="Toggle Dark Mode">
                    {darkMode ? 'Light Mode' : 'Dark Mode'}
                </button>
            </div>

            {/* Help Toggle outside of pageWrapper 
            <button 
                className={styles.helpToggle} 
                onClick={toggleHelp} 
                aria-expanded={helpOpen} 
                aria-controls="help-panel"
            >
                {helpOpen ? 'Hide Help' : 'Show Help'}
            </button>

            {/* HelpPanel outside of pageWrapper 
            {helpOpen && (
                <div id="help-panel" className={styles.helpPanel}>
                    <h3>How to Use This Page</h3>
                    <ul>
                        <li><strong>View Tasks:</strong> Click on any task to see its details.</li>
                        <li><strong>Search & Filter:</strong> Use the search bar to quickly find specific tasks by name.</li>
                        <li><strong>Dark Mode:</strong> Toggle between light and dark modes for comfort.</li>
                        <li><strong>Progress Bar:</strong> Keep track of how many tasks are completed at a glance.</li>
                    </ul>
                </div>
            )}
            */}



            {/* Main content in pageWrapper */}
            <div className={styles.pageWrapper}>
                <div className={styles.headerSection}>
                    <h2 className={styles.title}>
                        {workflow.workflow.name}
                    </h2>
                    <div className={styles.description}>{parse(workflow.workflow.description)}</div>

                    <div className={styles.overallProgressContainer}>
                        <div className={styles.overallProgressHeader}>
                            <div className={styles.overallProgressLabel}>
                                {completedCount} of {totalTasks} steps completed
                            </div>
                            <div className={styles.tooltipContainer}>
                                <span className={styles.tooltipIcon} tabIndex="0" role="button" aria-label="More info about progress">?</span>
                                <div className={styles.tooltipText}>
                                    This bar shows how many tasks are completed out of the total steps.
                                </div>
                            </div>
                        </div>
                        <div className={styles.overallProgressBarBackground}>
                            <div 
                                className={styles.overallProgressBarFill} 
                                style={{width: `${completionPercent}%`}}
                            />
                        </div>
                    </div>
                </div>

                <div className={styles.tasksSection}>
                    <WorkflowTasks 
                        tasks={tasks} 
                        workflowId={id}
                    />
                </div>
            </div>
        </div>
    );
}

export default WorkflowDetail;
