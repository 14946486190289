import React, { useState, useCallback } from 'react';
import axios from 'axios';
import config from '../../config';
import styles from '../../styles/components/WorkflowDetails/FormItem.module.css';

export const FormItem = ({ item, onChange, answer, workflowId, onSavingStateChange }) => {
    const [currentValue, setCurrentValue] = useState(item.fieldValue || answer || '');

    const debounce = (func, delay) => {
        let timer;
        return function(...args) {
            clearTimeout(timer);
            timer = setTimeout(() => func(...args), delay);
        };
    };

    const saveData = useCallback((fieldId, value) => {
        const authToken = localStorage.getItem('authToken');
        onSavingStateChange(true);
        axios.patch(`${config.apiUrl}/user-task-fields/${fieldId}/`, {
            fieldValue: value
        }, {
            headers: { 'Authorization': `Token ${authToken}` }
        })
        .then(() => {
            onSavingStateChange(false);
        })
        .catch(error => {
            console.error('Save failed', error);
            onSavingStateChange(false);
        });
    }, [workflowId, onSavingStateChange]);

    const debouncedSave = useCallback(debounce(saveData, 1000), [saveData]);

    const handleChange = (e) => {
        const newValue = e.target.value;
        setCurrentValue(newValue);
        onChange(newValue, item.fieldName);
        debouncedSave(item.id, newValue);
    };

    const fieldLabel = item.fieldName || "Field";
    const placeholder = `Enter ${item.fieldName}`;
    const isInformation = item.fieldType === 'information';

    const hintText = (item.hint) ? item.hint : '';
    return (
        <div className={styles.formItemContainer}>
            {!isInformation && (
                <label htmlFor={item.id} className={styles.formLabel}>
                    {fieldLabel}
                </label>
            )}

            {item.fieldType === 'text' ||
             item.fieldType === 'number' ||
             item.fieldType === 'password' ? (
                <>
                    <input
                        type={item.fieldType}
                        id={item.id}
                        value={currentValue}
                        onChange={handleChange}
                        placeholder={placeholder}
                        className={styles.formInput}
                        aria-label={fieldLabel}
                    />
                    {hintText && <div className={styles.fieldHint}>{hintText}</div>}
                </>
            ) : isInformation ? (
                <div className={styles.informationField} aria-label="Information">
                    {fieldLabel}
                </div>
            ) : (
                <p className={styles.unsupportedField}>
                    Unsupported field type: {item.fieldType}
                </p>
            )}
        </div>
    );
};

export default FormItem;
