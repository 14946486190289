// src/components/common/NavTabs.js
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import '../../styles/components/common/NavTabs.css';

// src/components/common/NavTabs.js

function NavTabs({ tabs, selectedTab, onTabSelect }) {
  const [underlineStyle, setUnderlineStyle] = useState({});
  const activeTabRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    if (activeTabRef.current && containerRef.current) {
      const tabRect = activeTabRef.current.getBoundingClientRect();
      const containerRect = containerRef.current.getBoundingClientRect();
      
      setUnderlineStyle({
        left: activeTabRef.current.offsetLeft,
        width: activeTabRef.current.offsetWidth,
      });
    }
  }, [selectedTab, tabs]);

  return (
    <nav className="nav-tabs" ref={containerRef}>
      <ul>
        {tabs.map((tab, index) => (
          <li
            key={index}
            className={selectedTab === tab.label ? 'active' : ''}
            onClick={() => onTabSelect(tab.label)}
            ref={selectedTab === tab.label ? activeTabRef : null}
          >
            <Link to={tab.link || "#"}>{tab.label}</Link>
          </li>
        ))}
      </ul>
    </nav>
  );
}

export default NavTabs;
