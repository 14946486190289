import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import BrowseWorkflowCard from '../../components/workflow/BrowseWorkflowCard';
import Sidebar from '../../components/common/Sidebar';
import config from '../../config';
import '../../styles/pages/BrowseWorkflows/PreviewWorkflowDetails.module.css';
import { FaBell, FaUserCircle, FaSearch } from 'react-icons/fa';
import DashboardTitle from '../../components/common/DashboardTitle';
import NavTabs from '../../components/common/NavTabs';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function BrowseWorkflowList() {
  const [workflows, setWorkflows] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(true);
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const [addingStatus, setAddingStatus] = useState({}); // Tracks adding status for each workflow

  const [selectedTab, setSelectedTab] = useState('Browse'); // Manage selected tab

  const [selectedWorkflow, setSelectedWorkflow] = useState(null);

  const toggleProfileDropdown = () => {
    setIsProfileOpen(!isProfileOpen);
  };

  useEffect(() => {
    const fetchWorkflows = async () => {
      try {
        const response = await axios.get(`${config.apiUrl}/workflows/`, {
          headers: { Authorization: `Token ${localStorage.getItem('authToken')}` },
        });
        setWorkflows(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching workflows:', error);
        setLoading(false);
      }
    };
    fetchWorkflows();
  }, []);

  const handleAddWorkflow = (workflowId) => {
    // Set adding status to true for this workflow
    setAddingStatus((prevStatus) => ({ ...prevStatus, [workflowId]: true }));

    axios
      .post(
        `${config.apiUrl}/user-workflows/`,
        { workflow_id: workflowId, status: 'active' },
        { headers: { Authorization: `Token ${localStorage.getItem('authToken')}` } }
      )
      .then((response) => {
        toast.success("Workflow Added Successfully! Your workflow has been added to the dashboard.");
        // Optional: Refetch or update the UI if needed
      })
      .catch((error) => {
        console.error('Failed to add workflow:', error.response?.data || error.message);
        toast.error('Failed to add workflow. Please try again.');
      })
      .finally(() => {
        // Reset adding status regardless of success or failure
        setAddingStatus((prevStatus) => ({ ...prevStatus, [workflowId]: false }));
      });
  };

  const filteredWorkflows = workflows.filter(
    (workflow) =>
      workflow.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      workflow.description.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const tabs = [
    { label: 'Browse', link: '/browse-workflows' },
    ];

  return (
    <div className="dashboard-container">
      {/* Uncomment the Sidebar if needed */}
      {/* <Sidebar /> */}
      <div className="content">
        <div className="dashboard-header">
          <DashboardTitle
            title="Browse Workflows"
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            searchPlaceholder="Search workflows..."
          />
          <NavTabs tabs={tabs} selectedTab={selectedTab} onTabSelect={setSelectedTab} />
        </div>


        <div className="workflow-list">
          {loading ? (
          <div className="loading-container">
            <div className="loading-spinner"></div>
            <div className="loading-message">Loading workflows...</div>
          </div>
          ) : filteredWorkflows.length > 0 ? (
            filteredWorkflows.map((workflow) => (
              <BrowseWorkflowCard
                key={workflow.id}
                workflow={workflow}
                isAdding={addingStatus[workflow.id]}
                onAdd={() => handleAddWorkflow(workflow.id)}
              />
            ))
          ) : (
            <div>No workflows found.</div>
          )}
        </div>
      </div>
    </div>
  );
}

export default BrowseWorkflowList;
