import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import UserWorkflowCard from '../../components/workflow/UserWorkflowCard';
import Sidebar from '../../components/common/Sidebar';
import DashboardTitle from '../../components/common/DashboardTitle';
import NavTabs from '../../components/common/NavTabs';
import WorkflowDetail from '../../pages/UserDashboard/WorkflowDetail';
import config from '../../config';
import '../../styles/pages/UserDashboard/UserWorkflowList.css';
import DeleteConfirmationModal from '../../components/Modals/DeleteConfirmationModal';
import { FaSearch } from 'react-icons/fa';

function UserWorkflowList() {
  const [workflows, setWorkflows] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(true);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [workflowToDelete, setWorkflowToDelete] = useState(null);

  const [selectedTab, setSelectedTab] = useState('Workflows'); // Manage selected tab
  const [selectedWorkflow, setSelectedWorkflow] = useState(null);

  const navigate = useNavigate();

  const handleDelete = (workflow) => {
    setWorkflowToDelete(workflow);
    setIsModalOpen(true);
  };

  const confirmDelete = () => {
    axios.delete(`${config.apiUrl}/user-workflows/${workflowToDelete.id}`, {
      headers: {
        Authorization: `Token ${localStorage.getItem('authToken')}`,
      },
    })
      .then(() => {
        setWorkflows(workflows.filter(workflow => workflow.id !== workflowToDelete.id));
        setIsModalOpen(false);
      })
      .catch(error => {
        console.error('Failed to delete the workflow:', error);
      });
  };

  const cancelDelete = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const fetchWorkflows = async () => {
      try {
        const response = await axios.get(`${config.apiUrl}/user-workflows/`, {
          headers: { Authorization: `Token ${localStorage.getItem('authToken')}` },
        });
        setWorkflows(response.data);
        setLoading(false);
        console.log('Fetched workflows:', response.data);
      } catch (error) {
        console.error('Error fetching workflows:', error);
        setLoading(false);
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('authToken');
          window.location.href = '/login';
        }
      }
    };
    fetchWorkflows();
  }, []);

  const filteredWorkflows = workflows.filter(workflow =>
    workflow.workflow.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const tabs = [
    { label: 'Workflows', link: '/user-dashboard' },
  ];
  

  const handleWorkflowClick = (workflow) => {
    setSelectedWorkflow(workflow); // Set selected workflow

    navigate(`/user-dashboard/workflows/${workflow.id}`);
  };

  return (
    <div className="dashboard-container">
      <div className="content">
        {/* Dashboard Header */}
        <div className="dashboard-header">
          <DashboardTitle
            title="Dashboard"
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            searchPlaceholder="Search workflows..."
          />
          <NavTabs
            tabs={tabs}
            selectedTab={selectedTab}
            onTabSelect={setSelectedTab}
          />
        </div>

          <div className="workflow-list">
            {loading ? (
            <div className="loading-container">
                <div className="loading-spinner"></div>
                <div className="loading-message">Loading workflows...</div>
            </div>
            ) : filteredWorkflows.length > 0 ? (
              filteredWorkflows.map((workflow) => (
                <UserWorkflowCard
                  key={workflow.id}
                  workflow={workflow}
                  isUserDashboard={true}
                  onDelete={() => handleDelete(workflow)}
                />
              ))
            ) : (
              <div>No workflows found.</div>
            )}
          </div>
        
      </div>

      {/* Delete Confirmation Modal */}
      {workflowToDelete && (
        <DeleteConfirmationModal
          isOpen={isModalOpen}
          onClose={cancelDelete}
          onConfirm={confirmDelete}
          workflowName={workflowToDelete.workflow.name}
        />
      )}
    </div>
  );
}

export default UserWorkflowList;
