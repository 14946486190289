import React from 'react';
import harri from './harri.png'; // Placeholder image
import '../../styles/WorkflowCard.css'; // Reusing WorkflowCard styles for consistency
import { FaUsers } from 'react-icons/fa'; // Importing the users icon
import { Link } from 'react-router-dom'; // Importing Link from react-router-dom
import { ClipLoader } from 'react-spinners';

function BrowseWorkflowCard({ workflow, isAdding, onAdd }) {
  return (
    <div className="workflow-card">
      <div className="workflow-card-header">
        <img src={harri} alt={workflow.name} className="workflow-logo" />
        <div className="image-separator"></div> {/* Gray line separator */}
      </div>
      <div className="workflow-card-body">
        <h2>{workflow.name}</h2>
        <div className="workflow-card-header-info">
          <div className="user-count">
            <FaUsers size={14} /> <span className="user-count-text">{workflow.user_count}</span>
          </div>
        </div>
        <p className="workflow-type">IT</p> {/* Placeholder for workflow type */}
        <p className="workflow-description">
          {workflow.description || 'No description available.'}
        </p>
      </div>
      <div className="workflow-card-footer">
        <Link to={`/browse-workflows/workflows/${workflow.id}`} className="view-workflow-button">
          <i className="fas fa-eye"></i> View
        </Link>
        <button 
          className="add-workflow-button" 
          onClick={onAdd} 
          disabled={isAdding} // Disable button while adding
        >
          {!isAdding && <i className="fas fa-plus"></i>} {/* Show icon only when not adding */}
          {isAdding ? (
                  <>
                  <ClipLoader size={9} color="#ffffff" />
                  {' Adding...'}
              </>
          ) : 'Add'}
        </button>

      </div>
    </div>
  );
}

export default BrowseWorkflowCard;
