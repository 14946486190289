import React, { useState, useEffect } from 'react';
import '../../styles/components/common/Sidebar.css';
import { NavLink } from 'react-router-dom';
import logo from '../../assets/images/workflow_logo.png';
import {
  FaProjectDiagram, // New icon for 'Browse Workflows'
  FaTools,            // New icon for 'Admin Dashboard'
  FaSignOutAlt,
  FaBell,
  FaUserCircle,
} from 'react-icons/fa';
import config from '../../config';
import axios from 'axios';

function Sidebar() {
  const [isExpanded, setIsExpanded] = useState(false);
  const [user, setUser] = useState({
    firstName: '',
    lastName: '',
    email: '',
    gender: '',
    dateOfBirth: '',
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const authToken = localStorage.getItem('authToken');
        const response = await axios.get(`${config.apiUrl}/user-profile/`, {
          headers: {
            'Authorization': `Token ${authToken}`
          }
        });
        setUser({
          firstName: response.data.first_name,
          lastName: response.data.last_name,
          email: response.data.email,
          gender: response.data.gender,
          dateOfBirth: response.data.date_of_birth,
        });
        setLoading(false);
      } catch (error) {
        console.error('Failed to fetch user profile:', error);
        setError('Failed to fetch user profile. Please try again.');
        setLoading(false);
      }
    };

    fetchUserProfile();
  }, []);

  const handleMouseEnter = () => {
    setIsExpanded(true);
  };

  const handleMouseLeave = () => {
    setIsExpanded(false);
  };

  const handleLinkClick = () => {
    // No action needed since we're not using a toggle button
  };

  return (
    <div
      className={`sidebar ${isExpanded ? 'expanded' : 'collapsed'}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {/* Site Header */}
      <NavLink
        to="/user-dashboard"
        className="site-header"
        onClick={handleLinkClick}
      >
        <img src={logo} alt="WorkflowMaster Logo" className="logo" />
        {isExpanded && <span className="site-title">WorkflowMaster</span>}
      </NavLink>

      {/* Menu Items */}
      <div className="menu-container">
        <ul className="menu-items">
          <li>
            <NavLink
              to="/user-dashboard"
              className={({ isActive }) =>
                isActive ? 'sidebar-link active' : 'sidebar-link'
              }
              onClick={handleLinkClick}
            >
              <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#e8eaed"><path d="M520-640v-160q0-17 11.5-28.5T560-840h240q17 0 28.5 11.5T840-800v160q0 17-11.5 28.5T800-600H560q-17 0-28.5-11.5T520-640ZM120-480v-320q0-17 11.5-28.5T160-840h240q17 0 28.5 11.5T440-800v320q0 17-11.5 28.5T400-440H160q-17 0-28.5-11.5T120-480Zm400 320v-320q0-17 11.5-28.5T560-520h240q17 0 28.5 11.5T840-480v320q0 17-11.5 28.5T800-120H560q-17 0-28.5-11.5T520-160Zm-400 0v-160q0-17 11.5-28.5T160-360h240q17 0 28.5 11.5T440-320v160q0 17-11.5 28.5T400-120H160q-17 0-28.5-11.5T120-160Zm80-360h160v-240H200v240Zm400 320h160v-240H600v240Zm0-480h160v-80H600v80ZM200-200h160v-80H200v80Zm160-320Zm240-160Zm0 240ZM360-280Z"></path></svg>
              {isExpanded && <span>Dashboard</span>}
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/browse-workflows"
              className={({ isActive }) =>
                isActive ? 'sidebar-link active' : 'sidebar-link'
              }
              onClick={handleLinkClick}
            >
              <FaProjectDiagram className="menu-icon" /> {/* Updated icon */}
              {isExpanded && <span>Browse Workflows</span>}
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/admin-dashboard"
              className={({ isActive }) =>
                isActive ? 'sidebar-link active' : 'sidebar-link'
              }
              onClick={handleLinkClick}
            >
              <FaTools className="menu-icon" />
              
              {isExpanded && <span>Admin Dashboard</span>}
            </NavLink>
          </li>
        </ul>
      </div>

      {/* Bottom Menu Items */}
      <div className="bottom-menu-container">
        <ul className="menu-items bottom-menu-items">
          <div className="nav-divider"></div>
          <li>
            <NavLink
              to="/notifications"
              className={({ isActive }) =>
                isActive ? 'sidebar-link active' : 'sidebar-link'
              }
              onClick={handleLinkClick}
            >
              <FaBell className="menu-icon" />
              {isExpanded && <span>Notifications</span>}
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/profile"
              className={({ isActive }) =>
                isActive ? 'sidebar-link active' : 'sidebar-link'
              }
              onClick={handleLinkClick}
            >
              <FaUserCircle className="menu-icon" />
              {isExpanded && <span>{user.firstName + ' ' + user.lastName}</span>}
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/logout"
              className={({ isActive }) =>
                isActive ? 'sidebar-link active' : 'sidebar-link'
              }
              onClick={handleLinkClick}
            >
              <FaSignOutAlt className="menu-icon" />
              {isExpanded && <span>Logout</span>}
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Sidebar;
