import React, { useState, useEffect, useRef } from 'react';
import styles from './FieldTypes.module.css';

export const SpinnerFieldConfig = [
  "fieldLabel",
  "required",
  "help_text",
  "choices",
  "default_value",
];

function SpinnerField({ field, onConfigChange }) {
  const [label, setLabel] = useState(field.config.fieldLabel);
  const [choices, setChoices] = useState(field.config.choices || "");
  const [editingChoices, setEditingChoices] = useState(false); // Toggle textarea visibility
  const editorRef = useRef(null);

  const handleLabelChange = (e) => {
    const updatedConfig = { ...field.config, fieldLabel: e.target.value };
    setLabel(e.target.value); // Update local label state
    onConfigChange(updatedConfig); // Notify WorkflowBuilder of the updated config
  };

  const handleChoicesChange = (e) => {
    const newChoices = e.target.value;
    setChoices(newChoices);
    const updatedConfig = { ...field.config, choices: newChoices };
    onConfigChange(updatedConfig); // Update choices in WorkflowBuilder
  };

  const handleSelectClick = () => {
    setEditingChoices(true); // Show textarea for editing choices
  };

  // Close the editor if a click is detected outside
  const handleClickOutside = (event) => {
    if (editorRef.current && !editorRef.current.contains(event.target)) {
      setEditingChoices(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setLabel(field.config.fieldLabel);
    setChoices(field.config.choices || ""); // Set initial choices from config
  }, [field.config.fieldLabel, field.config.choices]);

  return (
    <div className={styles.spinnerField}>
      <textarea
        className={styles.fieldLabel}
        value={label}
        onChange={handleLabelChange}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            e.currentTarget.blur();
          }
        }}
        onInput={(e) => {
          e.target.style.height = 'auto';
          e.target.style.height = `${e.target.scrollHeight}px`;
        }}
        rows="1"
        wrap="soft"
      />

      <div ref={editorRef}>
        {editingChoices ? (
          <div>
            <select className={styles.spinnerInput} onClick={handleSelectClick}>
              {choices.split('\n').map((choice, index) => (
                <option key={index} value={choice}>{choice}</option>
              ))}
            </select>
            <textarea
              className={styles.choicesEditor}
              value={choices}
              onChange={handleChoicesChange}
              placeholder="Enter choices, one per line"
              rows={5}
            />
          </div>
        ) : (
          <select className={styles.spinnerInput} onClick={handleSelectClick}>
            {choices.split('\n').map((choice, index) => (
              <option key={index} value={choice}>{choice}</option>
            ))}
          </select>
        )}
      </div>
    </div>
  );
}

export default SpinnerField;
