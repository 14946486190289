import React, { useState, useEffect } from 'react';
import styles from './FieldTypes.module.css';

// List of configuration keys
export const CheckboxFieldConfig = [
  "fieldLabel",
  "required",
  "help_text",
  "default_value",
];

function CheckboxField({ field, onConfigChange }) {
  const [label, setLabel] = useState(field.config.fieldLabel);

  const handleLabelChange = (e) => {
    const updatedConfig = { ...field.config, fieldLabel: e.target.value };
    setLabel(e.target.value); // Update local label state
    onConfigChange(updatedConfig); // Notify WorkflowBuilder of the updated config
  };

  useEffect(() => {
    setLabel(field.config.fieldLabel);
  }, [field.config.fieldLabel]);

  return (
    <div className={styles.checkboxField}>
      <input type="checkbox" className={styles.checkboxInput} disabled />
      <textarea
        className={styles.fieldLabel}
        value={label}
        onChange={handleLabelChange}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            e.currentTarget.blur();
          }
        }}
        onInput={(e) => {
          e.target.style.height = 'auto';
          e.target.style.height = `${e.target.scrollHeight}px`;
        }}
        rows="1"
        wrap="soft"
      />
    </div>
  );
}

export default CheckboxField;
