import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from '../../styles/components/WorkflowDetails/WorkflowTasks.module.css';

const WorkflowTasks = ({ tasks, workflowId }) => {
    const navigate = useNavigate();
    const [searchQuery, setSearchQuery] = useState('');

    const filteredTasks = tasks.filter(task =>
        task.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    // Updated handleStepClick to open in new tab
    const handleStepClick = (index) => {
        const taskId = filteredTasks[index].id;
        const url = `/user-dashboard/workflows/${workflowId}/step/${taskId}`;
        window.open(url, '_blank'); // open in a new tab
    };

    const isOverdue = (dueDateStr) => {
        if (!dueDateStr) return false;
        const dueDate = new Date(dueDateStr);
        const now = new Date();
        return dueDate < now;
    };

    return (
        <div className={styles.tasksContainer}>
            <div className={styles.searchBarContainer}>
                <input
                    type="text"
                    className={styles.searchInput}
                    placeholder="Search tasks..."
                    value={searchQuery}
                    onChange={e => setSearchQuery(e.target.value)}
                    aria-label="Search tasks by name"
                />
                {searchQuery && (
                    <button
                        className={styles.clearFilterButton}
                        onClick={() => setSearchQuery('')}
                        aria-label="Clear search"
                    >
                        View All Tasks
                    </button>
                )}
            </div>

            {filteredTasks.map((task, index) => {
                const isDone = task.status === "Done";
                const progressValue = isDone ? 100 : 10;
                const overdue = isOverdue(task.due_date);

                return (
                    <div
                        className={`${styles.taskItem}`}
                        key={task.id}
                        onClick={() => handleStepClick(index)}
                        title={`Click to view details of "${task.name}"`}
                        tabIndex="0"
                        role="button"
                        aria-label={`View details of ${task.name}`}
                    >
                        <div className={styles.taskHeader}>
                            <div className={styles.taskInfo}>
                                <span className={styles.stepNumber} aria-label={isDone ? 'Task Done' : 'Task In Progress'}>
                                    {isDone ? '✔️' : '→'} Step {index + 1}:
                                </span>
                                <span
                                    className={styles.taskName}
                                    style={{ color: isDone ? "#28a745" : "#007BFF" }}
                                >
                                    {task.name}
                                </span>
                                {task.assignee && (
                                    <span className={styles.assigneeContainer}>
                                        <img
                                            src={task.assignee.avatarUrl}
                                            alt={`${task.assignee.name}'s avatar`}
                                            className={styles.assigneeAvatar}
                                        />
                                        <span className={styles.assigneeName}>{task.assignee.name}</span>
                                    </span>
                                )}
                                {task.due_date && (
                                    <span
                                        className={styles.dueDate}
                                        style={{ color: overdue ? '#d9534f' : '#333' }}
                                    >
                                        Due: {new Date(task.due_date).toLocaleDateString()}
                                    </span>
                                )}
                            </div>
                        </div>
                        <div className={styles.progressBarContainer}>
                            <div
                                className={`${styles.progressBar} ${isDone ? styles.completed : styles.inProgress}`}
                                style={{ width: `${progressValue}%` }}
                            >
                                {progressValue}%
                            </div>
                        </div>
                    </div>
                );
            })}

            {filteredTasks.length === 0 && (
                <div className={styles.noResults}>
                    <p>No tasks match your search.</p>
                    <button
                        onClick={() => setSearchQuery('')}
                        className={styles.resetButton}
                    >
                        Reset Filters
                    </button>
                </div>
            )}
        </div>
    );
};

export default WorkflowTasks;
