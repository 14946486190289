// src/components/common/DashboardTitle.js
import React from 'react';
import { FaSearch } from 'react-icons/fa';
import '../../styles/components/common/DashboardTitle.css';

function DashboardTitle({ title, searchQuery, setSearchQuery, searchPlaceholder }) {
  return (
    <div className="dashboard-title-container">
      <h1>{title}</h1>

      {/* Search Bar */}
      <div className="search-container">
        <FaSearch className="search-icon" />
        <input
          type="text"
          value={searchQuery}
          onChange={e => setSearchQuery(e.target.value)}
          placeholder={searchPlaceholder || "Search..."}
        />
      </div>
    </div>
  );
}

export default DashboardTitle;
