import { descriptions, labels, types, fieldTypes } from '../FieldTypes/FieldDictionaries';
import styles from '../../styles/components/WorkflowBuilder/FieldConfigWindow.module.css';
import { useState, useEffect } from 'react';

function FieldConfigWindow({ field, onClose, onConfigChange, showOriginalNavbar }) {
  const [activeTab, setActiveTab] = useState('general');
  const [fieldConfig, setFieldConfig] = useState(field.config || {});

  const handleFieldConfigChange = (key, value) => {
    const updatedConfig = { ...fieldConfig, [key]: value };
    setFieldConfig(updatedConfig);
    onConfigChange(updatedConfig);
  };

  useEffect(() => {
    setFieldConfig(field.config || {});
  }, [field.config]);

  useEffect(() => {
    const configWindowTop = showOriginalNavbar ? '102px' : '42px';
    document.documentElement.style.setProperty('--config-window-top', configWindowTop);
  }, [showOriginalNavbar]);

  return (
    <div className={styles.configWindow}>
      <div className={styles.header}>
        <h3>{field.type} Properties</h3>
        <button className={styles.closeButton} onClick={onClose}>
          <i className="fa fa-times"></i>
        </button>
      </div>

      <div className={styles.tabContainer}>
        <button
          className={activeTab === 'general' ? styles.activeTab : ''}
          onClick={() => setActiveTab('general')}
        >
          General
        </button>
        <button
          className={activeTab === 'advanced' ? styles.activeTab : ''}
          onClick={() => setActiveTab('advanced')}
        >
          Advanced
        </button>
      </div>

      <div className={styles.tabContent}>
        {Object.keys(fieldConfig).filter((key) => (types[key] || 'general') === activeTab).map((key) => (
          <div key={key} className={styles.formGroup}>
            <label>{labels[key] || key}</label>
            {fieldTypes[key] === 'textarea' ? (
              <textarea
                className={styles.fieldTextArea}
                value={fieldConfig[key] || ""}
                onChange={(e) => handleFieldConfigChange(key, e.target.value)}
                placeholder="Enter each choice on a new line"
                rows="4"
              />
            ) : (
              <input
                type={fieldTypes[key] || 'text'}
                value={fieldConfig[key] || ""}
                onChange={(e) =>
                  handleFieldConfigChange(
                    key,
                    e.target.type === 'checkbox' ? e.target.checked : e.target.value
                  )
                }
              />
            )}
            <p className={styles.description}>{descriptions[key] || "Configure this field"}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default FieldConfigWindow;
