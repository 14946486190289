
// Descriptions for each configuration key
export const descriptions = {
  fieldLabel: "Enter the label for this field",
  required: "Prevent submission if this field is empty",
  help_text: "Add a short description below the field",
  placeholder: "Add placeholder text inside the field box",
  max_length: "Set the maximum number of characters allowed",
  default_value: "Set the default value for this field",
  choices: "Add choices for users to select from (one per line)",
};

// Labels for each configuration key
export const labels = {
  fieldLabel: "Field Label",
  required: "Required",
  help_text: "Help Text",
  placeholder: "Placeholder",
  max_length: "Max Length",
  default_value: "Default Value",
  choices: "Choices",
};

// Types (General or Advanced) for each configuration key
export const types = {
  fieldLabel: "general",
  required: "general",
  choices: "general",
  help_text: "advanced",
  placeholder: "advanced",
  max_length: "advanced",
  default_value: "advanced",
};

// Field types (input types) for each configuration key
export const fieldTypes = {
  fieldLabel: "text",
  required: "checkbox",
  help_text: "text",
  placeholder: "text",
  max_length: "number",
  default_value: "text",
  choices: "textarea",
};
